export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Powerballers Athletic Club',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    "The Powerballers Athletic Center is a place where athletes from all over can come and train their skills and their body under one roof. Our coaches are available to help athletes get the most out of their training. Camps and clinics offer kids an opportunitity to learn a new sport or sharpen up their skills for the sport of their choice. We look forward to working with local colleges, high schools, and youth teams in the Lehigh Valley to help progress their athletes' passion!",
  'client_theme.ThemeSidebar.learn_more': 'Learn More', // leave a space here so we don't display this for main instance.
};
